<template>
  <div class="page print-certificate">
    <div class="print-header">
      <img alt="STLLAB Logo" src="@/assets/logo.svg">
    </div>
    <div class="print-body has-text-centered">
      <h2 class="title is-4">DECLARACIÓN DE CONFORMIDAD</h2>
      <svg id="barcode"></svg>
      <p class="title is-4">{PRODUCT NAME}</p>
      <p>
        <span class="has-text-weight-bold">Producto sanitario a medida clase IIa</span><br>
        Nº declaración de conformidad: <span class="has-text-weight-bold">{order number}</span>
      </p>
      <hr class="mt-6">
      <p class="pt-3 pb-3">
        <span class="icon icon-lab"></span>
      </p>
      <p>
        <span class="has-text-weight-bold">FABRICANTE</span>
        <span class="has-text-weight-bold">{laboratory name}</span><br>
        {laboratory address}<br><br>
        Nº licencia sanitaria: <br>
        <span class="has-text-weight-bold">{license number}</span><br><br>
        Fecha de fabricación: <br>
        <span class="has-text-weight-bold">{order date}</span>
      </p>
      <hr class="mt-6">
      <p class="pt-3">
        Este producto está destinado solo y exclusivamente por el paciente<br>
        <span class="has-text-weight-bold">{patient name}</span> al cual le ha sido prescripto por:
      </p>
      <p class="pt-3">
        <span class="has-text-weight-bold">{dentistry name}<br>
        {clinic name}</span>
      </p>
      <p class="pt-3">
        El producto certificado en este documento cumple con los requisitos generales de funcionamiento y seguridad<br>
        establecidos en el anexo I del Real Decreto 1591/2009 y del Reglamento Europeo 2017/745.
      </p>
      <p class="pt-3">
        En Málaga, {current date}<br/>
        Responsable técnico: <span class="has-text-weight-bold">Tommaso Brivio</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Certificate',
  data () {
    return {
      barcodeOptions: {
        height: 40
        // background: 'transparent'
      }
    }
  },
  methods: {},
  mounted () {
    JsBarcode('#barcode', '123213-21312asda-sad', this.barcodeOptions)
  }
}
</script>

<style scoped lang="scss">
.print-certificate{
  .print-header{
    display: flex;
    justify-content: center;
    align-content: center;
    height: 1.5cm;
    width: 100%;
    background-color: #57567C;
    img{
      align-self: center;
      height: 0.8cm;
    }
  }
  .print-body{
    padding: .5cm 0px;
    #barcode{
      width: 40%;
    }
    p{
      font-size: 0.4cm;
      .icon{
        font-size: 1.3cm;
      }
    }
  }
}
</style>
